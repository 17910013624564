<template>
  <body>
    <div class="wrapper-all">
      <header class="main-header menu-top">
        <div class="container">
          <div class="container-logo">
            <a href="#main" class="logo-link">
              <img src="../assets/images/logo.svg" alt="logo" />
            </a>
          </div>
          <div class="container-menu mobile-menu">
            <ul class="menu-list menu mobile-list">
              <!-- <li class="item-menu">
                <a href="#main" class="link-menu active-link">Main</a>
              </li> -->
              <li class="item-menu">
                <a href="#whyAudit" class="link-menu">Why audit</a>
              </li>
              <!-- <li class="item-menu">
                <a href="#types" class="link-menu">Types</a>
              </li> -->
              <li class="item-menu">
                <a href="#watch" class="link-menu">Our Audits</a>
              </li>
              <!-- <li class="item-menu">
                <a href="#expensive" class="link-menu">expensive</a>
              </li> -->
              <li class="item-menu">
                <a href="#whyUs" class="link-menu">Why us</a>
              </li>
              <li class="item-menu">
                <a href="#contactUs" class="link-menu">Contact Us</a>
              </li>
            </ul>
            <a href="#contactUs">
              <button type="button" class="btn mobile-btn">
                Request an Audit
              </button>
            </a>
          </div>
          <div class="container-menu-btn">
            <button type="button" class="menu-btn"></button>
            <button type="button" class="close-menu"></button>
          </div>
        </div>
      </header>
      <div id="main">
        <div class="wrapper-first-screen">
          <div class="container first-screen">
            <div class="container-left-side">
              <div class="container-main-info">
                <h1 class="main-title">
                  Detailed audit of smart contracts for vulnerabilities and
                  errors
                </h1>
                <p class="sub-title">
                  Get in touch with our team to request a quote for a smart
                  contract audit
                </p>
              </div>
              <div class="container-btn">
                <a href="#contactUs" type="button" class="btn big-btn">
                  Request an Audit
                </a>
                <a href="#watch" class="btn big-btn transparent-btn review-btn"
                  >Our Audits</a
                >
              </div>
            </div>
            <div class="container-right-side">
              <img src="../assets/images/Saly-11.webp" alt="main-image" />
              <!-- <img src="../assets/images/Saly-11.svg" alt="main-image" /> -->
              <div class="container-btn mobile-container-btn">
                <a href="#contactUs" type="button" class="btn big-btn">
                  Request an Audit
                </a>
                <a href="#watch" class="btn big-btn transparent-btn review-btn"
                  >Our Audits</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="whyAudit">
        <div class="wrapper-info-block what-audit">
          <div class="container">
            <div class="header-block">
              <h2 class="title-block">Why audit a smart contract?</h2>
            </div>
            <div class="container-text-info">
              <p class="info">
                An external audit of your smart contract will help to identify
                errors in the code, vulnerabilities, and check the program
                logic. A professional and high-quality audit will save your
                nerves and money.
              </p>
              <div class="item-info">
                <h3 class="small-title before-img bell">A Few numbers</h3>
              </div>
              <p class="info large">
                Even a minor mistake or flaw in the logic of a smart contract
                can lead to the loss of a lot of money.
              </p>
            </div>
            <div class="audit-container-blocks">
              <ul class="list-audit">
                <li class="item-audit">
                  <div class="container-img-audit">
                    <img
                      src="../assets/images/$50-million.svg"
                      alt="50 million"
                    />
                  </div>
                  <p class="info-audit">
                    Was stolen from The DAO due to a technical flaw in the smart
                    contract code
                  </p>
                </li>
                <li class="item-audit">
                  <div class="container-img-audit">
                    <img
                      src="../assets/images/$30-million.svg"
                      alt="30 million"
                    />
                  </div>
                  <p class="info-audit">
                    Was stolen due to a vulnerability in the Parity Ethereum
                    wallet.
                  </p>
                </li>
                <li class="item-audit">
                  <div class="container-img-audit">
                    <img src="../assets/images/12-months.svg" alt="12 months" />
                  </div>
                  <p class="info-audit">
                    A code with an error "hung" on the official Ethereum website
                    for months
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="types">
        <div class="wrapper-info-block types">
          <div class="container">
            <div class="container-types-audit">
              <div class="container-img-type">
                <img src="../assets/images/Saly-13.webp" alt="man sitting" />
                <!-- <img src="../assets/images/Saly-13.svg" alt="man sitting" /> -->
              </div>
              <div class="info-about-types">
                <div class="header-block">
                  <h2 class="title-block">Types of audits</h2>
                </div>
                <ul class="list-list-types">
                  <li class="item-types">
                    <div class="item-info">
                      <h3 class="small-title before-img visibility-off">
                        Private Audit
                      </h3>
                    </div>
                    <p class="info large">
                      A private audit is issued during the development of a
                      smart contract in order to identify and fix bugs and
                      vulnerabilities.
                      <br />
                      It takes place in 2 stages: first, an initial audit is
                      done and after fixing all the bugs identified in it, we
                      make the final audit. The entire audit process is strictly
                      confidential and secured by the NDA. The results are only
                      used to improve the contract during the development
                      process.
                    </p>
                  </li>
                  <li class="item-types">
                    <div class="item-info">
                      <h3 class="small-title before-img visibility">
                        Public Audit
                      </h3>
                    </div>
                    <p class="info large">
                      We issue you an official public Audit, containing
                      conclusion on the work of the smart contract, its weak
                      points and recommendations for optimization and
                      improvements. The Audit Report will be published on our
                      website and you can always refer to it.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="watch">
        <div class="wrapper-info-block condacted">
          <div class="container" id="review">
            <div class="wrapper-condacted">
              <div class="header-block">
                <h2 class="title-block title-conducted">
                  Some of the security audits we have conducted to date:
                </h2>
              </div>
              <div class="container-scroll-list">
                <ul class="list-scroll">

                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/beeHive.png"
                          alt="BeeHive logo"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          BeeHive smart-contract Audit report
                        </h4>
                        <p class="info-company">November 29, 2024</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1iWjAV1dmN8dvDemg_ZLd22wixRnOHHmV/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
              
              <li class="item-scroll">
                <div class="user-company">
                  <div class="container-avatar">
                    <img
                      style="
                        width: auto;
                        height: 100%;
                        display: block;
                        margin: auto;
                        "
                      src="../assets/images/audits/TrueFund.png"
                      alt="logo company"
                    />
                  </div>
                  <div class="wrapper-info-company">
                    <h4 class="name-company">
                      TrueFund Audit Report
                    </h4>
                    <p class="info-company">March 26, 2023</p>
                  </div>
                </div>
                <a
                  type="button"
                  target="_blank"
                  href="https://drive.google.com/file/d/1tMN8rTFSm_EWDBSsFojruoXD1PtYUFGB/view"
                  class="btn big-btn transparent-btn audit-btn"
                  >Check the Audit</a
                >
              </li>
              
              <!--  -->   
              
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/animal-farm.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          AnimalFarm Audit Report
                        </h4>
                        <p class="info-company">April 14, 2023</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1XWpAunWfbZMGpqNgcTSjTsj5sp-ZHMCL/edit"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  
                  <!--  -->   
              
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/DEXCoyote.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          DEX Coyote Audit Report
                        </h4>
                        <p class="info-company">March 26, 2023</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1aBh2TyJJ_sOV7YOHyOpIi4K5eYwGyLkX/view?usp=share_link"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  
                  <!--  -->             
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/BNBbull.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          BNBbull Audit Report
                        </h4>
                        <p class="info-company">March 14, 2023</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1eL3JPFSwnMu0EkFJh1taoKdOK1NwKSzj/view?usp=share_link"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  
                  <!--  -->                   
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/1xFarm.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          1xFarm Audit Report
                        </h4>
                        <p class="info-company">March 5, 2023</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1IjhdfTPam9DHhFmWbk14WUdsnwzUvM76/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  
                  <!--  --> 
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/POSNEAR.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          POSNEAR Audit report
                        </h4>
                        <p class="info-company">December 16, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1pYFpgjZSQdhGjbZW_lvEPMnsNc77IGge/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  --> 
                  
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/AlfaStaking.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          Alfa Staking Audit report
                        </h4>
                        <p class="info-company">December 9, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/117MxYUjCP_cnDZNlh86QXJNdHxDUoi7T/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  --> 
                  
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          style="
                            width: auto;
                            height: 100%;
                            display: block;
                            margin: auto;
                            "
                          src="../assets/images/audits/Stellum.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          Stellum Audit report
                        </h4>
                        <p class="info-company">November 25, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1SctU1TuBXwVqmqFF6binBqFXy6Cs3S_J/view?usp=share_link"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->                   
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/DeMountain.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          DeMountain Finance Audit report
                        </h4>
                        <p class="info-company">October 13, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1PjvVeNaWezCQ_ZvTZefNUGMUI95u5UvH/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->                   
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/CyberFarm.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          CyberFarm Audit report
                        </h4>
                        <p class="info-company">October 6, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1tpz7Gtg9qxI--M-iltcr83tUIS-cXLr4/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->                   
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/GoldenSeaDeFi.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          GoldenSeaDeFi Audit report
                        </h4>
                        <p class="info-company">September 30, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1T8o1JwCEdhsvMOcfj6XG7FOF19JD7RFm/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->                   
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/GoldenSpaceFinance.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          Golden Space Audit report
                        </h4>
                        <p class="info-company">September 28, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1U0NdD1zo-qMpPADfujyK6GaoZOYSyHCy/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->                   
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/BitGreen.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          BitGreen Audit report
                        </h4>
                        <p class="info-company">September 20, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/11CbV1u_1carzzhesSi_09YitqPt4P65F/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  --> 
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/HeadsTails.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          HeadsTails Audit report
                        </h4>
                        <p class="info-company">September 16, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1wYdgPf88X2TG7WasovWDiqAatHq6xiM3/edit"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  --> 
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/SoccerGoldBUSD.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          Soccer Gold BUSD Audit report
                        </h4>
                        <p class="info-company">September 13, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1_FQU9smPRFnWi91u7oyMdDghouvpPTD6/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->                  
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/PureFarm.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          PureFarmBUSD Audit report
                        </h4>
                        <p class="info-company">September 5, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1YJ75XXrJdx7mHhATpSkKeCWa21-_GGUv/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/cropslogo.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          Binance Crops Farmer Audit report
                        </h4>
                        <p class="info-company">September 1, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1hUFJhQB18q8m6VOefI3YjO_64fGoZf6J/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/AtomBomb.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          AtomBombMiner Audit report
                        </h4>
                        <p class="info-company">August 30, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1Nn-vvEwjiPYPpPi2BWTrGHwgfQitCB4h/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <!--  -->
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/posduck.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          POSDuck Smartcontract Audit report
                        </h4>
                        <p class="info-company">August 24, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1caBns9UF3tEeRhV4U3fcdsd1MYnlV8r3/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/busdextra.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          BUSDextra Smartcontract Audit report
                        </h4>
                        <p class="info-company">August 23, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1DyvPJ3u5H2VsStegfIQYjyRZqkqO89QY/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/busd25.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          BUSD25 Smartcontract Audit report
                        </h4>
                        <p class="info-company">August 18, 2022</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1DyvPJ3u5H2VsStegfIQYjyRZqkqO89QY/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                    <li class="item-scroll">
                        <div class="user-company">
                          <div class="container-avatar">
                            <img
                              src="../assets/images/audits/bnb-capital.png"
                              alt="logo company"
                            />
                          </div>
                          <div class="wrapper-info-company">
                            <h4 class="name-company">
                              BNBCapital Smart-contracts Audit report
                            </h4>
                            <p class="info-company"> March 29, 2021</p>
                          </div>
                        </div>
                        <a
                          type="button"
                          target="_blank" 
                          href="https://drive.google.com/file/d/1iixhazaLkL9uoIa-mQUd7yMW-DfIBMDU/view"

                          class="btn big-btn transparent-btn audit-btn"
                          >Check the Audit</a
                        >
                    </li>
                    <li class="item-scroll">
                      <div class="user-company">
                        <div class="container-avatar">
                          <img
                            src="../assets/images/audits/meridian.png"
                            alt="logo company"
                          />
                        </div>
                        <div class="wrapper-info-company">
                          <h4 class="name-company">
                            Meridian.Finance Smartcontract Audit report
                          </h4>
                          <p class="info-company"> February 18, 2021</p>
                        </div>
                      </div>
                      <a
                        type="button"
                        target="_blank" 
                        href="https://drive.google.com/file/d/10K1fbhoVmdPBkd58cFMw0JmzJBmwS2-w/view"

                        class="btn big-btn transparent-btn audit-btn"
                        >Check the Audit</a
                      >
                  </li>
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/cryptoBank.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          CryptoBank Smartcontract Audit report
                        </h4>
                        <p class="info-company">December 1, 2020</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1fSRcWOjH024_66eE5jpYIp3Tf9ftg7lo/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                  <li class="item-scroll">
                    <div class="user-company">
                      <div class="container-avatar">
                        <img
                          src="../assets/images/audits/beeHive.png"
                          alt="logo company"
                        />
                      </div>
                      <div class="wrapper-info-company">
                        <h4 class="name-company">
                          BeeHive Smartcontract Audit report
                        </h4>
                        <p class="info-company">October 14, 2020</p>
                      </div>
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      href="https://drive.google.com/file/d/1Bq3nQrUN6JKPQB_PXdSWEwzBK0YQJR8K/view"
                      class="btn big-btn transparent-btn audit-btn"
                      >Check the Audit</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-info-block expensive" id="expensive">
        <div class="container">
          <div class="container-price-contract">
            <div class="container-img-contract">
              <!-- <img src="../assets/images/Saly-1.svg" alt="man flying" /> -->
              <img src="../assets/images/Saly-1.webp" alt="man flying" />
            </div>
            <div class="container-info-contract">
              <div class="header-block">
                <h2 class="title-block">Is smart contract audit expensive?</h2>
              </div>
              <div class="list-contract">
                <p class="text-contract">
                  Smart contract auditing is one of the most important tasks in
                  blockchain projects, as security should be a priority to avoid
                  important issues once the code is run.
                </p>
                <p class="text-contract">
                  Failure to do so could result in lost and lost thousands of
                  dollars, or, at best, more gas wasted in contracts than
                  necessary due to inefficiency.
                </p>
                <p class="text-contract">
                  Keep in mind that when you manage smart contracts, you usually
                  deal with a lot of money from people who have trusted you.
                  Imagine the problems you would face if, after a mass sale, if
                  it gets hacked and all the money is lost.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="whyUs">
        <div class="wrapper-info-block errors">
          <div class="container">
            <div class="header-block center-block">
              <h2 class="title-block">Why us?</h2>
              <p class="sub-title">
                An external audit of your smart contract will help to identify
                errors in the code, vulnerabilities,
              </p>
            </div>
            <div class="container-list-errors">
              <ul class="errors-list">
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    We fulfill our obligations. We are responsible. We comply
                    with the terms
                  </p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    We value our reputation and always focus on the quality of
                    work
                  </p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">We focus on long-term cooperation</p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    We provide thorough verification and highly informative
                    feedback on your contract design
                  </p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    We value our reputation and always focus on the quality of
                    work
                  </p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    We help projects improve their security and build secure
                    technology.
                  </p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    We combine leading formal verification technology with
                    best-in-class cybersecurity experts to deliver end-to-end
                    security services
                  </p>
                </li>
                <li class="item-errors">
                  <div class="check checked-item">
                    <img src="../assets/images/check.svg" alt="check" />
                  </div>
                  <p class="text-error">
                    GenesisLab's auditing service consists of top security
                    experts.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-info-block contacts-section" id="contactUs">
        <div class="container">
          <div class="left-block contacts">
            <div class="header-block">
              <h2 class="title-block">Contact Us</h2>
              <p class="sub-title">
                You can request an audit by filling this form or by writing to
                us at any time. Please provide as many details as
                possible to help our team prepare an accurate estimate.
              </p>
              <div class="contact-info">
                <p class="email">info@genesislab.ltd</p>
                <ul class="contact-list">
                  <li class="contact-item">
                    <a
                      href="https://t.me/Genesislab_ltd"
                      target="_blank"
                      rel="nofollow"
                      class="contact-link telegram"
                    >
                      <img src="../assets/images/telegram.svg" alt="telegram" />
                    </a>
                  </li>
                  <li class="contact-item">
                    <a
                      href="https://t.me/GenesisLab_chat"
                      target="_blank"
                      rel="nofollow"
                      class="contact-link telegram"
                    >
                      <img src="../assets/images/telegram.svg" alt="telegram" />
                    </a>
                  </li>
                  <li class="contact-item">
                    <a 
                      href="https://twitter.com/Genesislab_ltd"
                      target="_blank" 
                      rel="nofollow"
                      class="contact-link twitter">
                        <img src="../assets/images/twitter.svg" alt="twitter"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="container-contact">
            <template v-if="isShowForm">
              <!-- <div>
                <b-spinner label="Loading..."></b-spinner>
              </div> -->
              <form v-on:submit="submitForm" class="contact-form">
                <input
                  required
                  v-model="name"
                  type="text"
                  class="text-field"
                  placeholder="Your Name"
                  onkeyup="var yratext=/['0-9']/; if(yratext.test(this.value)) this.value=''"
                />
                <input
                    required
                  v-model="email"
                  type="email"
                  class="text-field"
                  placeholder="Your E-mail"
                />
                <div class="emailValidation">
                  <label v-if="isValidEmail">email is not valid</label>
                </div>
                <input
                  required
                  v-model="phone"
                  type="text"
                  class="text-field"
                  placeholder="Telegram ID"
                 ref="tg"
                  @click="comTel"

                />
                <textarea
                  v-model="message"
                  class="text-field field-area"
                  rows="7"
                  placeholder="Your Message"
                ></textarea>
                <button type="submit" class="btn submit-btn">Send</button>
              </form>
            </template>
            <template v-else>
              <div class="complete-send">
                <div v-if="isShowInfo" class="block-complete">
                  <p class="info-complete">The application has been sent</p>
                </div>
                <div v-else class="block-complete">
                  <p class="info-waiting">Please, waiting...</p>
                </div>
                <!--                        <button type="submit" class="btn transparent-btn send-again">send again</button>-->
                <button
                  :disabled="[isShowInfo, !isShowForm]"
                  type="submit"
                  class="btn submit-btn"
                >
                  Send
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="container">
          <div class="container-menu">
            <ul class="menu-list menu-bottom">
              <li class="item-menu">
                <a href="#main" class="link-menu">Main</a>
              </li>
              <li class="item-menu">
                <a href="#whyAudit" class="link-menu">Why audit</a>
              </li>
              <li class="item-menu">
                <a href="#types" class="link-menu">Types</a>
              </li>
              <li class="item-menu">
                <a href="#watch" class="link-menu">Our Audits</a>
              </li>
              <li class="item-menu">
                <a href="#expensive" class="link-menu">expensive</a>
              </li>
              <li class="item-menu">
                <a href="#whyUs" class="link-menu">Why us</a>
              </li>
              <li class="item-menu">
                <a href="#contactUs" class="link-menu">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </body>
</template>

<script>
import $ from "jquery";
import * as axios from "axios";
import IMask from 'imask';

export default {
  name: "LandingPage",
  props: {},
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      message: null,
      result: null,
      isValidEmail: false,
      eventName: "blur",
      isShowForm: true,
      isShowInfo: true,
    };
  },
    methods: {isLetter(e) {let char = String.fromCharCode(e.keyCode);if (/^[A-Za-z]+$/.test(char)) return true; else e.preventDefault();},
		comTel: function (){
			let currencyMask = IMask(
				this.$refs.tg,
				{
					mask: '@num',
					blocks: {
						num: {
							// nested masks are available!
							mask: String,
							thousandsSeparator: ' '
						}
					}
				});
			currencyMask()
    },
    changeForm: function () {
      if (!this.validEmail(this.email)) {
        // alert('Please, enter correct email address');
        this.isValidEmail = true;
        if (this.eventName !== "input") {
          this.eventName = "input";
        }
      } else this.isValidEmail = false;
    },
    submitForm: function (e) {
      let vm = this;
      this.isValidEmail = false;
      this.isShowForm = false;
      this.isShowInfo = false;
      axios
        .post("https://formspree.io/f/xrgoklow", {
          name: vm.name,
          email: vm.email,
          phone: vm.phone,
          message: vm.message,
        })
        .then((response) => {
          if (response.status === 200) {
            // alert('Your application has been successfully sent')
            this.isShowInfo = true;
            setTimeout(() => {
              this.isShowForm = true;
              this.isShowInfo = false;
            }, 3000);
          }
        })
        .catch((e) => {
          if (e.response.data.errors[0].message === "should be an email") {
            alert("Field email must be valid: <login@domen> ");
          }
        });
      this.name = null;
      this.email = null;
      this.phone = null;
      this.message = null;
      e.preventDefault();
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  mounted() {
    $(document).ready(function () {
      function Scroll_block() {
        let scroll_top = $(document).scrollTop();
        $(".menu a").each(function () {
          let hash = $(this).attr("href");
          let target = $(hash);
          if (
            target.position().top <= scroll_top &&
            target.position().top + target.outerHeight() > scroll_top
          ) {
            $(".link-menu").removeClass("active-link");
            $(this)
              .closest(".item-menu")
              .find(".link-menu")
              .addClass("active-link");
          } else {
            $(this)
              .closest(".item-menu")
              .find(".link-menu")
              .removeClass("active-link");
          }
        });
      }

      $(document).on("scroll", Scroll_block);

      $("a[href^='#']").click(function (e) {
        e.preventDefault();

        $(document).off("scroll");
        $(".link-menu").removeClass("active-link");
        $(this)
          .closest(".item-menu")
          .find(".link-menu")
          .addClass("active-link");
        let hash = $(this).attr("href");
        let target = $(hash);

        $("html, body").animate(
          {
            scrollTop: target.offset().top,
          },
          500,
          function () {
            window.location.hash = hash;
            $(document).on("scroll", Scroll_block);
          }
        );
      });

      $(".menu-btn").on("click", function () {
        $(this)
          .closest(".menu-top")
          .find(".mobile-menu")
          .addClass("active-menu");
        $(this)
          .closest(".menu-top")
          .find(".container-menu-btn")
          .addClass("toggle-btn");
      });

      $('.mobile-menu li').on("click", function () {
        $(this)
          .closest(".menu-top")
          .find(".mobile-menu")
          .removeClass("active-menu");
        $(this)
          .closest(".menu-top")
          .find(".container-menu-btn")
          .removeClass("toggle-btn");
      });
      
      $('.mobile-menu .mobile-btn').on("click", function () {
        $(this)
          .closest(".menu-top")
          .find(".mobile-menu")
          .removeClass("active-menu");
        $(this)
          .closest(".menu-top")
          .find(".container-menu-btn")
          .removeClass("toggle-btn");
      });
      
      $(".close-menu").on("click", function () {
        $(this)
          .closest(".menu-top")
          .find(".mobile-menu")
          .removeClass("active-menu");
        $(this)
          .closest(".menu-top")
          .find(".container-menu-btn")
          .removeClass("toggle-btn");
      });

      $(".review-btn").on("click", "a", function (event) {
        event.preventDefault();
        let id = $(this).attr("href"),
          top = $(id).offset().top;
        $("body,html").animate({ scrollTop: top }, 500);
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.emailValidation {
  color: red;
  margin-top: -15px;
  margin-bottom: 15px;
  font-size: 0.9em;
}
</style>
